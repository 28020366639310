<template>
  <b-card
    no-body
    class="mb-0"
  >
    <b-form-checkbox-group
      id="checkbox-group-2"
      v-model="selected"
      name="flavour-2"
    >
      <table class="table table-bordered">
        <thead>
          <tr>
            <th colspan="2" class="text-center">{{this.roleName}}</th>
          </tr>
          <tr>
            <th>Наименование</th>
            <th>Статус</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(value, index) in permissions"
            :key="index"
          >
            <td>{{ t[value.name] ? t[value.name] : value.name }}</td>
            <td>
              <p
                v-for="(child, childIndex) in value.childs"
                :key="childIndex"
              >
                <b-form-checkbox
                  :value="child.id"
                  class="custom-control-primary"
                >
                  {{ t[child.name.split('.')[1]] ? t[child.name.split('.')[1]] : child.name.split('.')[1] }}
                </b-form-checkbox>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </b-form-checkbox-group>
    <b-row>
      <b-col col="12">
        <b-button
          variant="primary"
          class="m-2 float-right"
          type="button"
          @click="save"
        >
          Сохранить
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BFormCheckbox, BFormCheckboxGroup, BButton, BRow, BCol,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import http from '@axios'
// Notification
import { useToast } from 'vue-toastification/composition'

export default {
  components: {
    BCard,
    BFormCheckbox,
    BFormCheckboxGroup,
    BButton,
    BRow,
    BCol,
  },
  data() {
    return {
      permissions: {},
      selected: [],
      roleName: '',
    }
  },
  setup() {
    const userData = ref(null)
    const { id } = router.currentRoute.params
    const t = {
      user: 'Пользователь',
      role: 'Роль',
      permission: 'Привилегии',
      category: 'Категория',
      book: 'Книга',
      'chatter-category': 'Категория форум',
      'chatter-discussion': 'Тема форум',
      'chatter-post': 'Ответить на форум',
      course: 'Курс',
      'course-module': 'Модуль курс',
      chapter: 'Форум',
      lesson: 'Урок',
      event: 'События',
      blog: 'Новости',
      slider: 'Слайдер',
      'home-dashboard': 'Главная панель',
      testimonial: 'Отзыв',
      'activity-log': 'Журналь активности',
      certificate: 'Сертификат',
      menu: 'Меню',
      service: 'Услуги',
      about: 'О проекте',
      'created-project': 'Для кого создан проект',
      'footer-menu': 'Футер',
      'bbb-setting': 'Настройка вебинар',
      'bbb-meeting': 'Вебинар',
      chat: 'Чат',
      test: 'Тест',
      create: 'Добавить',
      update: 'Изменить',
      delete: 'Удалить',
      view: 'Просмотр',
      sync: 'Синхронизация',
      'create-or-update': 'Добавить или изменить',
      'own-view': 'Собственные данные',
    }
    return {
      userData,
      id,
      t,
    }
  },
  created() {
    const toast = useToast()
    http.post('https://edu.startupchoikhona.tj/backend/api/permission/index', {})
      .then(response => {
        const {
          data,
        } = response.data
        this.permissions = data
        http.post(`https://edu.startupchoikhona.tj/backend/api/permission/list/${this.id}`, {})
          .then(response2 => {
            const data2 = response2.data
            // eslint-disable-next-line array-callback-return
            Array.from(data2, value2 => {
              this.selected.push(value2.id)
            })
          }).catch(error => {
            toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Ошибка',
                icon: 'XCircleIcon',
                variant: 'danger',
                text: error.data.message,
              },
            })
          })
      }).catch(error => {
        toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Ошибка',
            icon: 'XCircleIcon',
            variant: 'danger',
            text: error.data.message,
          },
        })
      })
    this.$http
      .post(`https://edu.startupchoikhona.tj/backend/api/role/${this.id}/view`, {
      })
      .then(response => {
        const {
          data,
        } = response.data
        this.roleName = data.display_name
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Ошибка при получение услуги',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  methods: {
    save() {
      this.$http.post(`https://edu.startupchoikhona.tj/backend/api/permission/sync/${this.id}`, { permissions: this.selected })
        .then(response => {
          console.log(response)
        }).catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Ошибка',
              icon: 'XCircleIcon',
              variant: 'danger',
              text: error.data.message,
            },
          })
        })
    },
  },
}
</script>

<style>

</style>
